import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { TextField, TextFieldTheme } from 'wix-ui-tpa';
import { useField as useFormikField } from 'formik';

import { Field, FieldWrapper } from '../../../../../../types';
import { FieldLabel } from '../../FieldLabel';
import { FieldPrivacyIcon } from '../../FieldPrivacyIcon';
import { DataHook } from '../../../../../../constants/DataHook';
import { classes, st } from '../Text/Text.st.css';
import { useFieldLabel } from '../../../../../../hooks';

type ErrorType = { value: string } | undefined;

export const NumberField: FC<FieldWrapper> = ({
  theme,
  formikFieldSelector,
}) => {
  const { t } = useTranslation();
  const { getFieldLabel } = useFieldLabel();
  const fieldContainerRef = useRef<HTMLDivElement>(null);
  const fieldRef = useRef<TextField>(null);
  const [isClearButtonVisible, setClearButtonVisibility] = useState(false);
  const [formikField, meta, helpers] =
    useFormikField<Field>(formikFieldSelector);
  const [htmlValidityState, setHtmlValidityState] = useState<ValidityState>();
  const [error, setError] = useState<string>();
  const fieldValidationMessage = (meta.error as unknown as ErrorType)?.value;

  useEffect(() => {
    if (fieldValidationMessage) {
      setError(fieldValidationMessage);
    } else if (htmlValidityState?.valid === false) {
      setError(t('app.widget.fields.validation.invalid-number'));
    } else {
      setError(undefined);
    }
  }, [t, fieldValidationMessage, htmlValidityState?.valid]);

  const field = formikField.value;
  const isFieldThemeBox = theme === TextFieldTheme.Box;
  const { required, infoTooltipText, value, key, id } = field;

  return (
    <div data-hook={key} ref={fieldContainerRef}>
      <FieldLabel
        text={getFieldLabel(field)}
        htmlFor={id}
        isFieldRequired={required}
        infoTooltipText={infoTooltipText}
      />
      <FieldPrivacyIcon
        formikFieldSelector={formikFieldSelector}
        data-hook={DataHook.FieldPrivacyIcon}
      >
        <TextField
          id={id}
          className={st(classes.field, { box: isFieldThemeBox })}
          data-hook={DataHook.TextField}
          value={
            value ??
            '' /* Need to pass empty string, for formik form reset to work*/
          }
          ref={fieldRef}
          theme={theme}
          type="number"
          errorMessage={error}
          withClearButton={isClearButtonVisible}
          error={!!error}
          onClear={() => {
            helpers.setValue({ ...field, value: '' });
            fieldRef.current?.focus();
          }}
          onChange={(event) => {
            helpers.setValue({
              ...field,
              value: event.target?.value,
            });
            setClearButtonVisibility(!!event.target?.value);
            setHtmlValidityState(event.target?.validity);
          }}
          onFocus={() => setClearButtonVisibility(!!value)}
          onBlur={(event) => {
            const isClearButtonFocused = fieldContainerRef.current?.contains(
              event.relatedTarget,
            );
            if (!isClearButtonFocused) {
              setTimeout(() => setClearButtonVisibility(false), 100);
            }
          }}
        />
      </FieldPrivacyIcon>
    </div>
  );
};
