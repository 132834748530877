import React, { FC } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { useField as useFormikField } from 'formik';
import {
  DatePickerInput,
  DatePickerInputProps,
  TextFieldTheme,
} from 'wix-ui-tpa';

import { classes, st } from './DatePicker.st.css';
import { Field, FieldWrapper } from '../../../../../../types';
import { FieldLabel } from '../../FieldLabel';
import { FieldPrivacyIcon } from '../../FieldPrivacyIcon';
import { useFieldLabel } from '../../../../../../hooks';

interface DatePickerProps extends FieldWrapper {
  allowFutureDates?: boolean;
}

const getDatePattern = (language: string): string => {
  return Intl
    ? Intl.DateTimeFormat(language, {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
      })
        .format(new Date(Date.UTC(2000, 11, 31, 0, 0, 0)))
        .replace('31', 'DD')
        .replace('12', 'MM')
        .replace('2000', 'YYYY')
    : '';
};

export const DatePicker: FC<DatePickerProps> = ({
  theme,
  formikFieldSelector,
  allowFutureDates = true,
}) => {
  const { language } = useEnvironment();
  const { getFieldLabel } = useFieldLabel();
  const [formikField, , helpers] = useFormikField<Field>(formikFieldSelector);

  const field = formikField.value;
  const { required, infoTooltipText, value, id } = field;
  const locale = language as DatePickerInputProps['locale'];
  const isFieldThemeBox = theme === TextFieldTheme.Box;

  return (
    <div className={classes.root}>
      <FieldLabel
        htmlFor={id}
        text={getFieldLabel(field)}
        isFieldRequired={required}
        infoTooltipText={infoTooltipText}
      />
      <FieldPrivacyIcon formikFieldSelector={formikFieldSelector}>
        <DatePickerInput
          value={value && new Date(value)}
          className={st(classes.field, { box: isFieldThemeBox })}
          inputTheme={theme}
          showMonthDropdown
          showYearDropdown
          inputWidth="100%"
          locale={locale}
          placeholderText={getDatePattern(language)}
          filterDate={
            allowFutureDates ? undefined : (date) => date < new Date()
          }
          onChange={(newDate: Date) => {
            helpers.setValue({
              ...field,
              value: newDate?.toLocaleDateString('en-CA'),
            });
          }}
        />
      </FieldPrivacyIcon>
    </div>
  );
};
